// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pagess-index-js": () => import("/home/circleci/project/src/pagess/index.js" /* webpackChunkName: "component---src-pagess-index-js" */),
  "component---src-pagess-contact-js": () => import("/home/circleci/project/src/pagess/contact.js" /* webpackChunkName: "component---src-pagess-contact-js" */),
  "component---src-pagess-photos-js": () => import("/home/circleci/project/src/pagess/photos.js" /* webpackChunkName: "component---src-pagess-photos-js" */),
  "component---src-pagess-situation-js": () => import("/home/circleci/project/src/pagess/situation.js" /* webpackChunkName: "component---src-pagess-situation-js" */),
  "component---src-pagess-tarifs-js": () => import("/home/circleci/project/src/pagess/tarifs.js" /* webpackChunkName: "component---src-pagess-tarifs-js" */),
  "component---src-pagess-villas-js": () => import("/home/circleci/project/src/pagess/villas.js" /* webpackChunkName: "component---src-pagess-villas-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/circleci/project/.cache/data.json")

